import styled from 'styled-components';
// const MediumFont = 'Rubik-Medium';

const MainContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
height: 50px;
padding: 5px;
align-items: center;
font-size: 12px;
font-family: 'Rubik-Regular';
line-height: 15px;
padding-left: 200px;
> div: first-child {
  display: flex;
  width: 30%;
  justify-content: space-evenly;
  color: #979797;

  > div {
  }
}
> div: last-child {
  width: 50%;
  display: flex;
  justify-content: flex-end;
	padding-right: 30px;
	align-items: center;

  > span: first-child {
    color: #979797;
    margin-right: 10px;
  }
  > span: last-child {
    color: #000000;
  }
}
	@media(max-width: 950px) {
		padding-left: 0px;
	}
	@media(max-width: 1200px) {
		> div: first-child{
			width: 60%;
		}
		> div: last-child {
			width: 40%;
		}
	}
	@media(max-width: 500px) {
		> div: first-child{
			width: 70%;
		}
		> div: last-child {
			width: 30%;
			padding-right: 0px;
		}
	}
	@media(max-width: 435px) {
		display: flex;
		justify-content: space-between;
		flex-flow: column;
		align-items: center;

		> div: first-child{
			width: 100%;
		}
		> div: last-child {
			width: 100%;
			justify-content: center;
		}
	}
`;

export {
  MainContainer
};