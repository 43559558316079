import styled, { css } from 'styled-components';
import {Col, Row} from "react-bootstrap";

const RegularFont = 'Proxima-Nova-Regular';
import { ImageUrl } from '../../utils/constants';
const backgroundUrl = `url(${ImageUrl}/images/footer_preview.png)`;


const FooterPanel = styled.div`
  width: ${({hideContent}) => hideContent ? '100%' : 'calc(100% - 200px)'};
  float: ${({hideContent}) => hideContent ? 'left' : 'right'};;
  position: relative;
  overflow: hidden;
  height: 540px;
  font-family: ${RegularFont};
  background-image: ${backgroundUrl};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  background-color: white;
  margin-top: 40px;

  @media (min-width: 1200px) {
    background-size: 100% 100%;
  }

  @media (max-width: 1000px) {
    height: auto;
  }
`;

const FooterSectionHeader = styled.span`
  font-size: 18px;
  color: ${({ color }) => color ? color : 'rgb(0,57,70)'};
  margin-bottom: 10px;
`;

const StyledRow = styled(Row)`
  margin: 45px 0 20px 0;
  ul {
    list-style-type: none;
    padding: 0;
  }
`;

const FooterInformation = styled.p`
  font-size: ${({smallSize}) => smallSize ? '12px' : '14px'};
  line-height: 21px;
  color: rgb(139,139,139);
  margin-bottom: 0;
  margin-top: ${({information}) => information ? '16px' : '0'};
  //cursor: ${({ smallSize }) => smallSize && 'default'};
  span{
  cursor: pointer;
  }
  span > a{
    color: #337ab7;
    text-decoration: none;
  }
`;

const SocialInformation = styled(Col)`
  text-align: right;
  margin-top: 70px;
  padding: 0;
  
  @media (max-width: 768px) {
    text-align: left;
    width: 100%;
  }

  > ul {
    li {
      //cursor: pointer;
    }
  }
`;

const StyledColumn = styled(Col)`
  @media(max-width: 700px) {
    padding: 0px;
  }
  
  opacity: ${({ hide }) => hide ? 0 : 1};
  > ul {
    margin-top: 16px;
    margin: 16px auto 0;
    
    > li {
      > p {
        line-height: 24px;
      }
      >a {
        cursor: pointer;
      }
    }
  }
  text-align: left;
  @media (max-width: 767px) {
    margin-top: 20px;
    padding: 0;

    ${({ hide }) => hide && css`
      display: none;
    `}
  }
`;

const FooterMenuList = styled(Col)`
  padding: 0px;
  @media (max-width: 768px) and (min-width: 500px) {
    width: 100%;
    margin-top: 30px;
  }
  
  @media (max-width: 500px) {
    width: 100%;
    padding: 0;
  }
`;

const FooterInformationPanel = styled(Col)`
  padding: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SocialIcons = styled.div`
  width: 100%;
  float: left;
  margin-top: ${({ left }) => left ? '0' : '1vw'};
  
  > a {
    //cursor: pointer;
    > i {
      color: #8b8b8b;
      margin-right: 5%;
      font-size: 15px;
      cursor: pointer;
    }
  }
  
  ${({ left }) => left && css`
    > a {
      > i {
        color: #288e96;
        margin-left: 10px;
        margin-right: 0;
        cursor: pointer;
      }
      @media (max-width: 768px) {
        &:first-child {
          > i {
            margin-left: 0;
          }
        }
      }
    }
  `}
`;

const FooterContainer = styled(Col)`
  padding: 0;
`;

const InformationContainer = styled.div`
  width: 100%;
  padding: 140px 119px 0 98px;

  @media (max-width: 1000px) {
    padding: 140px 6vw 0 6vw;
  }

  > picture {
    div{
      > img {
      cursor: pointer;
      height: ${({height}) => height ? '40px' : '60px'};
      }

    }
    > img {
      cursor: pointer;
      height: ${({height}) => height ? '40px' : '60px'};
      }
  }
`;

export { FooterPanel, FooterSectionHeader, StyledRow, FooterInformation, SocialInformation, StyledColumn, FooterMenuList, FooterInformationPanel,
  SocialIcons, FooterContainer, InformationContainer };
