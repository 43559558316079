import React from 'react';
import { MainContainer } from './styles';

class LandingFooterPortalV3 extends React.Component {
  render() {
    return(
      <MainContainer>
        <div>
          <div>
            About
          </div>
          <div>
            Help Center
          </div>
          <div>
            Privacy Policy
          </div>
          <div>
            Terms of use
          </div>
        </div>
        <div>
          <span>@2021 </span>
          <span> Training Amigo LLC. All Rights Reserved</span>
        </div>
      </MainContainer>
    );
  }
}

export default LandingFooterPortalV3;