import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LOGO_URL } from '../../utils/config';
import { FooterPanel, FooterSectionHeader, StyledRow, FooterInformation, SocialInformation, SocialIcons, StyledColumn, FooterMenuList,
  FooterInformationPanel, FooterContainer, InformationContainer } from './styles';

import { ImageUrl } from '../../utils/constants';
import LazyImage from '../common/LazyImage/LazyImage';

const LandingFooterPanel = ({history, isInsidePages, isUser, companyLogo, hideContent}) => (
  <FooterPanel isInsidePages={isInsidePages} hideContent={hideContent}>
    <InformationContainer height={companyLogo ? 0 : 1}>
      {companyLogo ?
        <picture>
          <img src={`${ImageUrl}/${companyLogo}`} alt="logo"
            onClick={() => isUser ? history.push('/social') : history.push('/')}/>
        </picture>
        :
        LOGO_URL ?
          <picture>
            <source srcSet={ImageUrl + '/images/Logo/woliba_trans.png'} type="image/png"/>
            <LazyImage src={ImageUrl + '/images/Logo/woliba_trans.png'} onClick={() =>history.push('/')}/>
          </picture>
          : <picture>
            <source srcSet="/public/images/Logo/training-amigo-horizontal-logo.webp" type="image/webp"/>
            <source srcSet="/public/images/Logo/training-amigo-horizontal-logo.png" type="image/png"/>
            <img src="/public/images/Logo/training-amigo-horizontal-logo.png" alt="logo"
              onClick={() => isUser ? history.push('/social') : history.push('/')}/>
          </picture>
      }
      <StyledRow>
        <FooterContainer sm={12} style={{display: "flex"}} >
          <FooterInformationPanel sm={3}>
            <FooterSectionHeader color='rgb(0,57,70)'>Our Mission</FooterSectionHeader>
            <FooterInformation information>
              Woliba’s mission is to help create a culture of wellbeing so employees can thrive.
              By making work less stressful and teams more connected,
              Woliba is reimagining engagement, recognition and wellness for growing companies.
            </FooterInformation>
          </FooterInformationPanel>
          <FooterMenuList sm={5}>
            <StyledColumn sm={4} hide="true" hidden={true}>
              <FooterSectionHeader>Platform</FooterSectionHeader>
              <ul>
                <li><FooterInformation>Platform 1</FooterInformation></li>
                <li><FooterInformation>Platform 2</FooterInformation></li>
                <li><FooterInformation>Platform 3</FooterInformation></li>
                <li><FooterInformation>Platform 4</FooterInformation></li>
                <li><FooterInformation>Platform 5</FooterInformation></li>
                <li><FooterInformation>Platform 6</FooterInformation></li>
              </ul>
            </StyledColumn>
            <StyledColumn sm={4} style={{padding: "0px 15px"}}>
              <FooterSectionHeader>Support</FooterSectionHeader>
              <ul>
                <li><FooterInformation>If you have any questions, comments, or concerns, please reach out to us at </FooterInformation></li>
                <li><FooterInformation><span><a href="mailto:support@woliba.io">support@woliba.io</a></span></FooterInformation></li>
              </ul>
            </StyledColumn>
            <StyledColumn sm={4} hide="true">
              <FooterSectionHeader>Platform</FooterSectionHeader>
              <ul>
                <li><FooterInformation>Platform 1</FooterInformation></li>
                <li><FooterInformation>Platform 2</FooterInformation></li>
                <li><FooterInformation>Platform 3</FooterInformation></li>
                <li><FooterInformation>Platform 4</FooterInformation></li>
                <li><FooterInformation>Platform 5</FooterInformation></li>
                <li><FooterInformation>Platform 6</FooterInformation></li>
              </ul>
            </StyledColumn>
          </FooterMenuList>
          <SocialInformation sm={4}>
            <ul>
              <li />
              <li>
                <FooterSectionHeader>
                  <SocialIcons left>
                    <a href="https://twitter.com/wolibawellness" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" /></a>
                    <a href="https://www.facebook.com/wolibawellness/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
                    <a href="https://www.linkedin.com/company/wolibawellness/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in" /></a>
                    <a href="https://www.instagram.com/wolibawellness/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
                  </SocialIcons>
                </FooterSectionHeader>
              </li>
              <li><FooterInformation><span onClick={() => window.open('https://woliba.io/contact', '_blank' )}>Contact</span></FooterInformation></li>
              <li><FooterInformation><span onClick={() => window.open('https://woliba.io/privacy-policy', '_blank' )}>Privacy Policy</span></FooterInformation></li>
              <li><FooterInformation><span onClick={() => window.open('https://woliba.io/terms-of-use', '_blank' )}>Terms of use</span></FooterInformation></li>
              <li><FooterInformation smallSize><i className="far fa-copyright"/>{`${moment().format('YYYY')} Training Amigo LLC. All Rights Reserved.`}</FooterInformation></li>
            </ul>
          </SocialInformation>
        </FooterContainer>
      </StyledRow>
    </InformationContainer>
  </FooterPanel>
);

LandingFooterPanel.propTypes = {
  history: PropTypes.object.isRequired,
  isInsidePages: PropTypes.bool,
  isUser: PropTypes.bool,
  companyLogo: PropTypes.string,
  hideContent: PropTypes.bool
};

export default withRouter(LandingFooterPanel);