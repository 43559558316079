import styled from "styled-components";
const RegularFont = "Rubik-Light";
const BoldFont = "Rubik-Medium";
const FooterPanel = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  font-family: ${RegularFont};
  padding: 80px 0px 0 0px;
  .faq-link a{
    color:#24395A !important;
  }
  @media (max-width: 1FFFFFFpx) {
    padding: 30px 6vw;
  }
  @media (max-width: 500px) {
    padding: 30px 10px;
  }
  .divider{
    width:100%;
    margin-top: 30px;
    img{
      width:100%;
    }
  }
  @media (max-width: 4500px){
  .responsive{
    display: none;
  }
}
@media (max-width: 700px){
  .responsive{
    width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;  
  align-items: flex-start;
  // justify-content: space-around;
  >div:last-child{
    display: inline-block !important;
    padding: 0px 10px 0px 25px; 
    margin-top: 20px;
    width: 100%;
  }
  > div {
    display: inline-block !important;
    padding: 0px 10px 0px 25px; 
    margin-top: 20px;
    width: 50%;
  > picture {
    > img {
      cursor: pointer;
      margin-bottom: 35px;
      width: 180px;
    }
    >div{
      > img {
      cursor: pointer;
      margin-bottom: 35px;
      width: 180px;
    }

    }
  }
  h3 {
    font-family: ${BoldFont};
    margin-top: 35px;
    font-weight: 700;
    color: black;
  }
  p {
     max-width: 300px;
     font-family: 'Montserrat-Regular';
     color:  #24395A;
     font-size: 14px;
     line-height: 17px;
     font-weight: 400;
     font-style: normal;
     margin-bottom: 0px;
  }
  > ul {
    list-style-type: none;
    color: #FFFFFF;
    padding: 0px 0px;
    li{
      >a{
        color: #24395A;
      }
    }
      li:first-child {
         font-size: 18px;
         color: #24395A;
         font-weight: 500;
         style: normal;
         font-family: ${BoldFont};
         cursor: default;
      }
      li.secondBold{
         font-size: 16px;
         font-family: ${BoldFont};
         cursor: default;
      }
      li {
         color: #24395A;
         font-size: 14px;
         font-family: ${RegularFont};
         padding: 7px 0px;
         font-weight: 400;
         span {
            cursor: pointer;
         }
         img {
              width: 25px;
              margin-right: 8px;
            }
         i {
            font-size: 20px;
            color: red;
            margin: 0px 5px;
            padding: 0px 3px;
            cursor: pointer;
            font-weight: 900;
         }
         a:hover {
          text-decoration: none;
         }
      }
  }
  >div:first-child
  .email{
    font-family: 'Montserrat-Regular';
    font-size: 18px;
    font-weight: 500;
    line-height: 21.92px;
    color:  #24395A;
  }
}
  .support li:first-child {
    color: #FFFFFF;
    font-family: ${RegularFont}; 
    max-width: 270px;
    font-size: 15px;
  }
  
  .support li:nth-child(3) {
    > span {
      color: #FFFFFF;
      font-family: ${BoldFont};
    }
  } 
  .support li:last-child{
    > span {
      color: #FFFFFF;
      font-family: ${BoldFont};
    }
  }
  .support li a{
    >img{
      margin-right: 5px;
    }
  }
  .hide {
    display: none;
  }
  }
}
@media (max-width: 2500px){
.responsivefooterBottomContainer{
 display: none;
}
}
@media (max-width: 700px){
  .responsivefooterBottomContainer{
    flex-direction: column-reverse;
    margin: 40px 0px 40px 0px;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    ul {
        list-style-type: none;
        color: #FFFFFF;
        padding: 0px 0px;
        margin-bottom: 0px;
    li {
       float: left;
       cursor: pointer; 
       display:flex;
      a{
        margin-left:5px;
        >img{
          margin-left:5px;
        }
      }
    }
    }
    > div {
       color: #24395A;
       font-size: 14px;
       font-family: ${RegularFont};
       margin-left: 10px;
       @media (max-width: 708px) {
           margin-left: 10px;
           margin-top: 40px;
       } 
    }
  }
 }

  .footerBottomContainer {
    @media (max-width: 708px){
      display: none;
    }
    margin: 40px 0px 40px 0px;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    ul {
        list-style-type: none;
        color: #FFFFFF;
        padding: 0px 0px;
        margin-bottom: 0px;
    li {
       float: left;
       cursor: pointer; 
       display:flex;
      a{
        margin-left:5px;
        >img{
          margin-left:5px;
        }
      }
    }
    }
    > div {
       color: #24395A;
       font-size: 14px;
       font-family: ${RegularFont};
       margin-left: 10px;
       @media (max-width: 708px) {
           margin-left: 10px;
           margin-top: 40px;
       } 
    }
  }
`;
const InformationContainer = styled.div`
 @media (max-width: 700px){
  display: none;
 }
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;  
  > div {
    display: inline-block !important;
    padding: 0px 10px; 
  > picture {
    > img {
      cursor: pointer;
      margin-bottom: 35px;
      width: 180px;
    }
    >div {
      > img {
      cursor: pointer;
      margin-bottom: 35px;
      width: 180px;
    }

    }
  }
  h3 {
    font-family: ${BoldFont};
    margin-top: 35px;
    font-weight: 700;
    color: black;
  }
  p {
     max-width: 300px;
     font-family: 'Montserrat-Regular';
     color:  #24395A;
     font-size: 16px;
     line-height: 26px;
     font-weight: 400;
     font-style: normal;
     margin-bottom: 0px;
  }
  > ul {
    list-style-type: none;
    color: #FFFFFF;
    padding: 0px 0px;
    li{
      >a{        color: #24395A}
    }
      li:first-child {
         font-size: 18px;
         color: #24395A;
         font-weight: 500;
         style: normal;
         font-family: ${BoldFont};
         cursor: default;
      }
      li.secondBold{
         font-size: 16px;
         font-family: 'Montserrat-Regular';
         cursor: pointer;
      }
      li {
         color: #24395A;
         font-size: 16px;
         font-family: ${RegularFont};
         font-family: 'Montserrat-Regular';
         padding: 7px 0px;
         font-weight: 400;
         cursor: pointer;
         span {
            cursor: pointer;
         }
         img {
              width: 25px;
              margin-right: 8px;
            }
         i {
            font-size: 20px;
            color: red;
            margin: 0px 5px;
            padding: 0px 3px;
            cursor: pointer;
            font-weight: 900;
         }
         a:hover {
          text-decoration: none;
         }
         a{ 
          text-decoration: none;
         }
      }
  }
  >div:first-child
  .email{
    font-family: 'Montserrat-Regular';
    font-size: 18px;
    font-weight: 500;
    line-height: 21.92px;
    color:  #24395A;
  }
 }
  .support li:first-child {
    color: #FFFFFF;
    font-family: ${RegularFont}; 
    max-width: 270px;
    font-size: 15px;
  }
  
  .support li:nth-child(3) {
    > span {
      color: #FFFFFF;
      font-family: ${BoldFont};
    }
  } 
  .support li:last-child{
    > span {
      color: #FFFFFF;
      font-family: ${BoldFont};
    }
  }
  .support li a{
    >img{
      margin-right: 5px;
    }
  }
  .hide {
    display: none;
  }
`;

export { FooterPanel, InformationContainer };
