import React from 'react';
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {FooterPanel, InformationContainer} from './styles';
import {CookieBanner} from "../../components";
import {ImageUrl, imgPath} from '../../utils/constants';
import { COMPANY_NAME, LOGO_URL } from '../../utils/config';
import LazyImage from '../common/LazyImage/LazyImage';
const LandingFooterPanelV3 = ({history, isInsidePages, isUser, companyLogo}) => (
  <FooterPanel isInsidePages={isInsidePages}>
    <CookieBanner />
    <InformationContainer>
      <div>
        {companyLogo ?
          <picture>
            <img src={`${imgPath}/${companyLogo}`} alt="logo"
              onClick={() => isUser ? history.push('/social') : history.push('/')}/>
          </picture>
          :
          LOGO_URL ?
            <picture>
              <source srcSet={ImageUrl + "/images/Logo/Woliba.svg"} type="image/png"/>
              <LazyImage src={ImageUrl + "/images/Logo/Woliba.svg"} onClick={() => isUser ? history.push('/social') : history.push('/')}/>
            </picture>
            : <picture>
              <source srcSet="/public/images/Logo/training-amigo-horizontal-logo.webp" type="image/webp"/>
              <source srcSet="/public/images/Logo/training-amigo-horizontal-logo.png" type="image/png"/>
              <img src="/public/images/Logo/training-amigo-horizontal-logo.png" alt="logo"
                onClick={() => isUser ? history.push('/social') : history.push('/')}/>
            </picture>
        }
        <p>{`${COMPANY_NAME}'s mission is to help create a culture of wellbeing so employees can thrive.` +
          ` By making work less stressful and teams more connected, ${COMPANY_NAME} is reimagining engagement, recognition and wellness for companies.`
        }</p>
        <p  style={{color: "#24395A",fontSize: "18px",fontFamily: "Rubik-Medium",fontStyle: "normal",fontWeight: "500", marginTop:"30px", padding: "0px 0px 7px 0px"}}>Contact Us</p>
        <span style={{color: "#24395A",fontSize: "16px", fontFamily:"'Montserrat-Regular",padding: "10px 0px"}}>sales@woliba.io</span>
      </div>
      <div className="hide">
        <ul>
          <li>Company</li>
          <li className="secondBold" onClick={() => history.push('/')}>Home</li>
          <li><a href='https://woliba.io/blog' target="_blank" rel="noopener noreferrer">Blogs</a></li>
          {/* <li className="secondBold" onClick={() => history.push('/pricing')}>Pricing</li> */}
          <li><span onClick={() => history.push('/user')}>Sign In</span></li>
          <li><span onClick={() => history.push('/contact')}>Request a Demo</span></li>
        </ul>
      </div>
      <div>
        <ul>
          <li>Platform</li>
          <li><span onClick={() => history.push('/wellness')}>Wellness</span></li>
          <li><span onClick={() => history.push('/engagement')}>Engagement</span></li>
          <li><span onClick={() => history.push('/employee-rewards')}>Rewards</span></li>
          <li><span onClick={() => history.push('/employee-recognition')}>Recognition</span></li>
          {/* <li><span onClick={() => history.push('/community-page')}>Community</span></li> */}
          <li><span onClick={() => history.push('/wellness-admin')}>Admin</span></li>
        </ul>
      </div>
      <div>
        <ul>
          <li>Solutions</li>
          <li><span onClick={() => history.push('/activity-challenges')}>Activity Challenges</span></li>
          <li><span onClick={() => history.push('/wellness-challenges')}>Wellness Challenges</span></li>
          <li><span onClick={() => history.push('/fitness-videos')}>Fitness</span></li>
          <li><span onClick={() => history.push('/nutrition-recipes')}>Nutrition Recipes</span></li>
          <li><span onClick={() => history.push('/wellness-education')}>Wellness Education</span></li>
          <li><span onClick={() => history.push('/wellness-events')}>Events</span></li>
        </ul>
      </div>
      <div>
        <ul>
          <li>Support</li>
          <li><span onClick={() => history.push('/contact')}>Contact</span></li>
          {/* <li><span className='faq-link'><a href='/pricing#faq'>FAQs</a></span></li> */}
          {/* <li><span onClick={() => history.push('/pricing#faq')}>FAQs</span></li> */}
          <li><span onClick={() => history.push('/terms-use')}>Terms of Use</span></li>
          <li><span 
            onClick={() =>{
              // history.push('/privacy-policy');
              window.open('https://woliba.io/privacy-policy', '_blank' )
            }}>Privacy Policy</span></li>
        </ul>
      </div>
    </InformationContainer>
    <div className="responsive">
      <div style={{width:'100%', margin:'30px 0px'}}>
        {companyLogo ?
          <picture>
            <img src={`${imgPath}/${companyLogo}`} alt="logo"
              onClick={() => isUser ? history.push('/social') : history.push('/')}/>
          </picture>
          :
          LOGO_URL ?
            <picture>
              <source srcSet={ImageUrl + "/images/Logo/Woliba.svg"} type="image/png"/>
              <LazyImage src={ImageUrl + "/images/Logo/Woliba.svg"} onClick={() => isUser ? history.push('/social') : history.push('/')}/>
            </picture>
            : <picture>
              <source srcSet="/public/images/Logo/training-amigo-horizontal-logo.webp" type="image/webp"/>
              <source srcSet="/public/images/Logo/training-amigo-horizontal-logo.png" type="image/png"/>
              <img src="/public/images/Logo/training-amigo-horizontal-logo.png" alt="logo"
                onClick={() => isUser ? history.push('/social') : history.push('/')}/>
            </picture>
        }
        <p>{`${COMPANY_NAME}'s mission is to help create a culture of wellbeing so employees can thrive.` +
          ` By making work less stressful and teams more connected, ${COMPANY_NAME} is reimagining engagement, recognition and wellness for companies.`
        }</p>
        <p  style={{color: "#24395A",fontSize: "18px",fontFamily: "Rubik-Medium",fontStyle: "normal",fontWeight: "500", marginTop:"30px", padding: "0px 0px 7px 0px"}}>Contact Us</p>
        <span className="email">sales@woliba.io</span>
      </div>
      <div className="hide">
        <ul>
          <li>Company</li>
          <li> <span onClick={() => history.push('/')}>Home</span></li>
          {/* <li><span onClick={ () => history.push('/why-woliba')}>{COMPANY_NAME === 'Training Amigo' ? 'Why Amigo' : 'Why Us'}</span></li>
          <li><span onClick={() => history.push('/about')}>About</span></li> */}
          {/* <li><span onClick={() => history.push('/pricing')}>Pricing</span></li> */}
          <li><a href='https://woliba.io/blog' target="_blank" rel="noopener noreferrer">Blogs</a></li>
          <li><span onClick={() => history.push('/user')}>Sign In</span></li>
          <li><span onClick={() => history.push('/contact')}>Request a Demo</span></li>
        </ul>
      </div>
      <div>
        <ul>
          <li>Platform</li>
          <li><span onClick={() => history.push('/wellness')}>Wellness</span></li>
          <li><span onClick={() => history.push('/engagement')}>Engagement</span></li>
          <li><span onClick={() => history.push('/employee-rewards')}>Rewards</span></li>
          <li><span onClick={() => history.push('/employee-recognition')}>Recognition</span></li>
          {/* <li><span onClick={() => history.push('/community-page')}>Community</span></li> */}
          <li><span onClick={() => history.push('/wellness-admin')}>Admin</span></li>
        </ul>
      </div>
      <div>
        <ul>
          <li>Solutions</li>
          <li><span onClick={() => history.push('/activity-challenges')}>Activity Challenges</span>s</li>
          <li><span onClick={() => history.push('/wellness-challenges')}>Wellness Challenges</span></li>
          <li><span onClick={() => history.push('/fitness-videos')}>Fitness</span></li>
          <li><span onClick={() => history.push('/nutrition-recipes')}>Nutrition Recipes</span></li>
          <li><span onClick={() => history.push('/wellness-education')}>Wellness Education</span></li>
          <li><span onClick={() => history.push('/wellness-events')}>Events</span></li>
        </ul>
      </div>
      <div style={{width:'50%'}}>
        <ul>
          <li>Support</li>
          <li><span onClick={() => history.push('/contact')}>Contact</span></li>
          {/* <li><span className='faq-link'><a href='/pricing#faq'>FAQs</a></span></li> */}
          {/* <li><span onClick={() => history.push('/pricing')}>FAQs</span></li> */}
          <li><span onClick={() => history.push('/terms-use')}>Terms of Use</span></li>
          <li><span onClick={() => history.push('/privacy-policy')}>Privacy Policy</span></li>
        </ul>
      </div>
    </div>  
    {/* <div className="divider">
      <img src={`${imgPath}WellnessPage/FooterDividerImg.svg`} alt={"breaker"}/>
    </div> */}
    <LazyImage div={"divider"} src={ImageUrl + "/images/WellnessPage/FooterDividerImg.svg"}  alt={"breaker"}/>
    <div className="footerBottomContainer">
      <div>
        <i className="far fa-copyright"/>{`${moment().format('YYYY')} Training Amigo Inc. All Rights Reserved.`}
      </div>
      <ul className="support">
        <li>
          <a href="https://www.facebook.com/wolibawellness/" target="_blank" rel="noopener noreferrer"><LazyImage src={ImageUrl + "/images/footernewsocial/facebookImg.svg"} width="30" height="30" /></a>
          <a href="https://www.youtube.com/@wolibawellness" target="_blank" rel="noopener noreferrer"><LazyImage src={ImageUrl + "/images/footernewsocial/YoutubeImg.svg"} width="30" height="30" /></a>
          <a href="https://twitter.com/wolibawellness" target="_blank" rel="noopener noreferrer"><LazyImage src={ImageUrl + "/images/footernewsocial/twitterImg.svg"} width="30" height="30" /></a>
          <a href="https://www.linkedin.com/company/wolibawellness/" target="_blank" rel="noopener noreferrer"><LazyImage src={ImageUrl + "/images/footernewsocial/linkedinImg.svg"} width="30" height="30" /></a>
          <a href="https://www.instagram.com/wolibawellness/" target="_blank" rel="noopener noreferrer"><LazyImage src={ImageUrl + "/images/footernewsocial/instagramImg.svg"} width="30" height="30" /></a>
        </li>
      </ul>
    </div>
    <div className="responsivefooterBottomContainer">
      <div>
        <i className="far fa-copyright"/>{`${moment().format('YYYY')} Training Amigo Inc. All Rights Reserved.`}
      </div>
      <ul className="support">
        <li>
          <a href="https://www.facebook.com/wolibawellness/" target="_blank" rel="noopener noreferrer"><LazyImage src={ImageUrl + "/images/footernewsocial/facebookImg.svg"} width="30" height="30" /></a>
          <a href="https://www.youtube.com/@wolibawellness" target="_blank" rel="noopener noreferrer"><LazyImage src={ImageUrl + "/images/footernewsocial/YoutubeImg.svg"} width="30" height="30" /></a>
          <a href="https://twitter.com/wolibawellness" target="_blank" rel="noopener noreferrer"><LazyImage src={ImageUrl + "/images/footernewsocial/twitterImg.svg"} width="30" height="30" /></a>
          <a href="https://www.linkedin.com/company/wolibawellness/" target="_blank" rel="noopener noreferrer"><LazyImage src={ImageUrl + "/images/footernewsocial/linkedinImg.svg"} width="30" height="30" /></a>
          <a href="https://www.instagram.com/wolibawellness/" target="_blank" rel="noopener noreferrer"><LazyImage src={ImageUrl + "/images/footernewsocial/instagramImg.svg"} width="30" height="30" /></a>
        </li>
      </ul>
    </div>
  </FooterPanel>
);

LandingFooterPanelV3.propTypes = {
  history: PropTypes.object.isRequired,
  isInsidePages: PropTypes.bool,
  isUser: PropTypes.bool,
  companyLogo: PropTypes.string
};

export default withRouter(LandingFooterPanelV3);
